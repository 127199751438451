import React from "react"
import PropTypes from "prop-types"

import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import Column from "@src/components/Columns/Column"
import Columns from "@src/components/Columns/Columns"

import PaymentsCTA from "@src/components/PaymentsCTA"
import LoremIpsum from "@src/components/LoremIpsum"
import { ApplyUrlProvider } from "@src/hooks/useApplyUrl"

const PaymentsCtaPage = ({ location }) => {
  const title = "Payments CTA"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "SMALL_BUSINESS",
  }
  return (
    <ApplyUrlProvider location={location} attributionTopic="Business Software">
      <Page
        title={title}
        header={<Header />}
        headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
        footer={<Footer />}
      >
        <Columns>
          <Column width={{ desktop: 8, mobile: 12 }}>
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />

            <PaymentsCTA />

            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
          </Column>
          <Column width={{ desktop: 4, mobile: 12 }}>
            <PaymentsCTA onStickyRail />
          </Column>
        </Columns>{" "}
      </Page>
    </ApplyUrlProvider>
  )
}

PaymentsCtaPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export default PaymentsCtaPage
