import React from "react"
import PropTypes from "prop-types"

import { Text } from "@nerdwallet/react-typography"
import Box from "@src/components/Box"
import CtaCard, { Header, CTA, SelectionList } from "@src/components/CtaCard"
import StickyRail from "@src/components/StickyRail"
import { PAYMENTS_CTA_TEXT } from "@src/lib/constants"
import imageSource from "./ContactlessPaymentIllustration.svg"

import {
  buildElementPositionAttributes,
  buildProductShoppingAttributes,
} from "@src/lib/analytics_payloads"
import { withImpression } from "@src/lib/impression"

import styles from "./PaymentsCTA.module.less"
import { useApplyUrl } from "../../hooks/useApplyUrl"

const paymentsCTATrackData = {
  marketplace_institution_id: "9a1b65f4-bb1e-11ec-b478-3795cac11ebe",
  marketplace_product_id: "d868d954-bb1e-11ec-b891-b729f7f11e2d",
  marketplace_offer_id: "0b308ab2-bb1f-11ec-9b14-bbacc843abfe",
}

const TrackedCtaButton = withImpression(
  CTA,
  {
    eventType: "product_impression",
    payload: ({ props }) => ({
      ...buildElementPositionAttributes({
        sectionName: props["data-cta-type"],
      }),
      ...buildProductShoppingAttributes(paymentsCTATrackData),
      entity_name: props["data-cta-type"],
    }),
  },
  {
    propWithImpression: "href",
  }
)

const CTA_TYPE = "Payments CTA"

const PaymentsCTA = ({ onStickyRail }) => {
  const onContent = !onStickyRail
  const applyUrl = useApplyUrl({
    cta_type: CTA_TYPE,
  })

  const paymentsCtaCard = (
    <CtaCard
      sticky={onStickyRail}
      boundedWidth
      mobileOnly={onContent}
      centered={onContent}
      verticalMargin={onContent}
    >
      <img
        data-cy={`payments-cta-${onContent ? "on-content" : "on-sticky-rail"}`}
        className={styles.image}
        src={imageSource}
        alt="Business with open sign"
        width={343}
        height={214}
      />

      <Header alignLeft bottomMargin={false}>
        Accept payments, avoid surprises
      </Header>

      <SelectionList>
        <Text align="left">
          Answer a few questions to match your business with our selected
          payment partners.
        </Text>
      </SelectionList>

      <Box className={styles.button}>
        <TrackedCtaButton
          href={applyUrl}
          ctaType={CTA_TYPE}
          data-cta-type={CTA_TYPE}
        >
          {PAYMENTS_CTA_TEXT}
        </TrackedCtaButton>
      </Box>
    </CtaCard>
  )

  if (onContent) return paymentsCtaCard

  return <StickyRail opacityAnimation>{paymentsCtaCard}</StickyRail>
}

PaymentsCTA.propTypes = {
  onStickyRail: PropTypes.bool,
}

PaymentsCTA.defaultProps = {
  onStickyRail: false,
}

export default PaymentsCTA
